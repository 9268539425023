import { useMemo } from 'react';
import {
    ArrowLeft16,
    ArrowRight16,
    Close20,
    Currency20,
    Home20,
    ShoppingCart20,
    Blog20,
} from '@carbon/icons-react';
import { MouseEventHandler, ReactSVGElement, useEffect, useState } from 'react';
import { ReactComponent as EAgroLogo } from '../../assets/e-agro-logo-filled.svg';
import { useDropdownCategoriasBanner } from '../../hook/useDropDownCategoriasBanner';
import * as S from './LateralMenu.styles';
import { useGetBlogFeatureFlag } from '../../hook/useGetPostStaticContent';

interface ICategory {
    id?: string;
    name: string;
    children?: ICategory[] | boolean;
    url_path?: string;
    url?: string;
    svg?: JSX.Element;
}

type Props = {
    menuItems: ICategory[] | undefined;
    onClickDiv: MouseEventHandler<HTMLDivElement | ReactSVGElement>;
};

export const LateralMenu = (data: Props) => {
    const [currentItemsShowing, setCurrentItemsShowing] = useState<ICategory[]>();
    const [pageTitle, setPageTitle] = useState<string>('');
    const [currentLevel, setCurrentLevel] = useState<number>(0);
    const [currentItemSelectedOnList, setCurrentItemSelectedOnList] = useState<ICategory | null>();
    const { data: dataBanner } = useDropdownCategoriasBanner();

    //  Blog Feature Flag:
    const { loading: flagLoading, error: flagError, data: flagData } = useGetBlogFeatureFlag();
    const isBlogEnabled = useMemo(() => {
        if (!flagLoading && !flagError) {
            return flagData?.getPostStaticContent?.enabled || false;
        }
        return false;
    }, [flagData, flagLoading, flagError]);

    const isCurrentRouteActive = (route: string): boolean => {
        return window?.location.pathname.toLowerCase() === route.toLowerCase() && route !== '#';
    };

    const setInitialList = () => {
        const listItems: ICategory[] = [
            {
                name: 'Início',
                url: '',
                svg: <Home20 color="#0C881E" />,
            },
            {
                name: 'Quem somos',
                url: 'sobre',
                svg: <EAgroLogo color="#0C881E" />,
            },
            {
                name: 'Linhas de crédito',
                url: 'simulacao-de-credito',
                svg: <Currency20 color="#0C881E" />,
            },
        ];
        if (data.menuItems) {
            listItems.push({
                name: 'Produtos da loja',
                svg: <ShoppingCart20 color="#0C881E" />,
                children: true,
            });
        }

        //  Push blog button to the list:
        if (isBlogEnabled) {
            listItems.push({
                name: 'Blog',
                url: 'blog',
                svg: <Blog20 color="#0C881E" />,
            });
        }

        setCurrentItemsShowing(listItems);
    };

    useEffect(() => {
        setInitialList();
    }, [data.menuItems]);

    const setPageLevel = (decrease?: boolean) => {
        if (decrease) setCurrentLevel(currentLevel - 1);
        else setCurrentLevel(currentLevel + 1);
    };

    const setCurrentItem = (item: ICategory) => {
        if (currentLevel === 0) {
            setCurrentItemsShowing(
                data.menuItems?.sort((a, b) => (a.name < b.name ? 1 : b.name > a.name ? -1 : 0)),
            );
        } else if (currentLevel === 1) {
            setCurrentItemsShowing(item.children as ICategory[]);
        } else {
            window.location.href = process.env.REACT_APP_PUBLIC_URL + `loja/${item?.url_path}`;
        }

        setPageLevel();
    };

    const selectItem = (item: ICategory) => {
        setCurrentItem(item);
    };

    useEffect(() => {
        if (currentLevel === 2) {
            setPageTitle(currentItemSelectedOnList?.name || '');
            return;
        } else if (currentLevel === 1) {
            setPageTitle('Categorias');
        } else if (currentLevel === 0) {
            setPageTitle('');
        }

        setCurrentItemSelectedOnList(null);
    }, [currentLevel, currentItemSelectedOnList]);

    const goBack = () => {
        if (currentLevel === 2) {
            setCurrentItemsShowing(
                data.menuItems?.sort((a, b) => (a.name < b.name ? 1 : b.name > a.name ? -1 : 0)),
            );
        } else if (currentLevel === 1) {
            setInitialList();
        }

        setPageLevel(true);
    };

    return (
        <S.DarkerBackground onClick={data.onClickDiv}>
            <S.LateralMenuContainer
                onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }}
            >
                {currentLevel === 1 && (
                    <S.TitleContainer>
                        <S.Title>{pageTitle}</S.Title>
                        <Close20 onClick={data.onClickDiv} />
                    </S.TitleContainer>
                )}
                {currentLevel === 2 && (
                    <S.TitleContainer>
                        <S.GoBackRow onClick={goBack}>
                            <ArrowLeft16 />
                            <p>Voltar</p>
                        </S.GoBackRow>
                        <Close20 onClick={data.onClickDiv} />
                    </S.TitleContainer>
                )}
                {currentItemsShowing?.map((item, index) => {
                    return item.children ? (
                        item.children === true ? (
                            <S.LinkContainer key={index}>
                                {item.svg && item.svg}
                                <p onClick={() => selectItem(item)}>{item.name}</p>
                            </S.LinkContainer>
                        ) : (
                            <S.CategoryContainer onClick={() => selectItem(item)} key={index}>
                                {item.name} <ArrowRight16 />
                            </S.CategoryContainer>
                        )
                    ) : (
                        <S.LinkContainer key={index}>
                            {item.svg}
                            <a
                                className={`link-header ${
                                    isCurrentRouteActive(item.url || '') ? 'active-route' : ''
                                }`}
                                href={`${process.env.REACT_APP_PUBLIC_URL}${item.url}`}
                            >
                                {item.name}
                            </a>
                        </S.LinkContainer>
                    );
                })}
                {currentLevel === 1 && (
                    <a
                        title={dataBanner?.getMenuMarketplace?.title}
                        href={dataBanner?.getMenuMarketplace?.link}
                        onClick={(e: { preventDefault: () => void }) => {
                            e.preventDefault();
                            window.location.href =
                                process.env.REACT_APP_PUBLIC_URL +
                                dataBanner?.getMenuMarketplace?.link;
                        }}
                    >
                        <S.Banner
                            src={dataBanner?.getMenuMarketplace?.image_url}
                            alt={dataBanner?.getMenuMarketplace?.alt}
                            title={dataBanner?.getMenuMarketplace?.title}
                        />
                    </a>
                )}
            </S.LateralMenuContainer>
        </S.DarkerBackground>
    );
};
